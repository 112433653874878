<template lang="pug">
	main(ref="pagina")#servico
		BannerGeral(:contents="bannerContent", v-if="!hasBanner && !isOrganogramaPlano && !$store.state.loading")
		Conteudo(:contents="pagina")
		//- ServicosRelacionados
</template>

<script>
import pagina from '@/mixins/pagina'
import Conteudo from '@sections/Servico/Conteudo/Conteudo'
import ServicosRelacionados from '@sections/Servico/ServicosRelacionados/ServicosRelacionados'

export default {
  name: 'view-servico',
  mixins: [pagina],
  components: {
    Conteudo,
    ServicosRelacionados
  },
  metaInfo () {
    return {
      title: this.pagina.title,
      meta: [
        { name: 'description', content: '' }
      ]
    }
  },
  // data() {
  // 	return {
  // 		pagina: {
  // 			"title": "Fotografia Aérea e Restituição",
  // 			"namespace": "App\\Models\\Service",
  // 			"slug": "fotografia-aerea-e-restituicao",
  // 			"content": {
  // 				"setor": "Setor de Geoprocessamento",
  // 				"cadastro": "2018-12-06T17:01:49.99",
  // 				"description": "<p>C&oacute;pias digitais de Fotografias A&eacute;reas, Restitui&ccedil;&otilde;es Aerofotogram&eacute;tricas e de outros elementos cartogr&aacute;ficos do munic&iacute;pio est&atilde;o dispon&iacute;veis&nbsp;sem custos a quem delas precisar.</p>\r\n\r\n<p>O atendimento &eacute; feito pelo Setor de Geoprocessamento do Instituto de Pesquisa e Planejamento Urbano de Curitiba (IPPUC). As c&oacute;pias, em meio digital (formato JPG, TIF ou DWG), servem para comprova&ccedil;&atilde;o da exist&ecirc;ncia de edifica&ccedil;&otilde;es, arruamento ou outros elementos f&iacute;sicos.</p>\r\n\r\n<p>A solicita&ccedil;&atilde;o deve ser feita pelo e-mail abaixo, informando&nbsp;endere&ccedil;o,&nbsp;indica&ccedil;&atilde;o fiscal do lote&nbsp;ou&nbsp;regi&atilde;o de interesse (bairro, rua).</p>\r\n\r\n<p><strong>Prazo de atendimento</strong></p>\r\n\r\n<ul>\r\n\t<li>At&eacute; 5 (cinco) dias &uacute;teis</li>\r\n</ul>\r\n\r\n<p><strong>Para esclarecimento de d&uacute;vidas</strong></p>\r\n\r\n<ul>\r\n\t<li><strong>Telefone: </strong>(41) 3250-1352 ou 3250-1371</li>\r\n\t<li><strong>E-mail: </strong><a href=\"mailto:geoprocessamento@ippuc.org.br\">geoprocessamento@ippuc.org.br</a></li>\r\n</ul>",
  // 				"link": ""
  // 			}
  // 		}
  // 	}
  // },
  computed: {
    bannerContent () {
      return {
        content: { title: { pt: this.pagina.title } },
        backTo: {
          to: { name: 'Servicos' },
          page: 'Serviços'
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./Servico.styl"></style>
