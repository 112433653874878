<template lang="pug">
    section#conteudo
        .wrapper
            .flag(v-if="contents.content.link")
                span @ Serviço Online
            .tags
                .tag(v-if="contents.content.setor")
                    i
                    span {{ contents.content.setor }}
            h3 {{ contents.title }}
            .content(v-html="contents.content.description")
            a(:href="contents.content.link", target="_blank", v-if="contents.content.link").acessar
                | Acessar serviço
                FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
</template>

<script>
import { props } from '@/mixins/components'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'section-conteudo',
  props,
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="@stylus/defaultTextos.styl"></style>
<style lang="stylus" scoped src="./Conteudo.styl"></style>
