<template lang="pug">
    section#servicosRelacionados
        .wrapper
            h3 Serviços relacionados
            ul
                li(v-for="i in 3")
                    a(href="#")
                        h4 Duis aute irure dolor in
                        .tags
                            span.tag Geoprocessamento
                            span.tag Geoprocessamento
                        p
                            |Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet nunc quam. Mauris eu fringilla libero.
                            |Morbi feugiat elementum ultricies. Duis egestas nisi justo.
                        span.detalhes Ver detalhes
</template>

<script>
export default {
  name: 'section-servicos-relacionados'
}
</script>

<style lang="stylus" scoped src="./ServicosRelacionados.styl"></style>
